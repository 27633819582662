/* members */
const breakpoint = window.matchMedia('(min-width:992px)');
let membersGallery;

const breakpointChecker = function() {
  if ( breakpoint.matches === true ) {
    if ( membersGallery !== undefined ) membersGallery.destroy( true, true );
    return;
  } else if ( breakpoint.matches === false ) {
    return enableSwiper();
  }
};

const enableSwiper = function() {
  membersGallery = new Swiper('.swiper-container_members', {
    slidesPerView: 'auto',
    spaceBetween: 0,
  });
};

breakpoint.addListener(breakpointChecker);
breakpointChecker();