/* menu */
document.querySelector('.header__btn_menu').addEventListener('click', () => {
  document.querySelector('.menu_top').classList.toggle('visible');
});

document.querySelector('.menu_top .close').addEventListener('click', () => {
  document.querySelector('.menu_top').classList.remove('visible');
});

/* search */
document.querySelector('.header__btn_search').addEventListener('click', () => {
  document.querySelector('.header').classList.add('search-visible');
});

document.querySelector('.search .close').addEventListener('click', () => {
  document.querySelector('.header').classList.remove('search-visible');
});

document.querySelector('.search__field').addEventListener('keyup', (e) => {
  if(!e.target.value) {
    document.querySelector('.search__close').style.display = 'none';
  } else {
    document.querySelector('.search__close').style.display = 'block';
  }
});

document.querySelector('.search__close').addEventListener('click', (e) => {
  e.target.removeAttribute('style');
});