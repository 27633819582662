const columnsGallery = new Swiper('.gallery_columns', {
    slidesPerView: 'auto',
	spaceBetween: 0,
	breakpoints: {
        375: {
			slidesPerView: 'auto',
        },
        768: {
			slidesPerView: 3,
			slidesPerColumn: 3,
        },
	},
	navigation: {
		nextEl: '.pagination__nav_next',
		prevEl: '.pagination__nav_prev',
	},
	pagination: {
		el: '.pagination__inner',
		clickable: false,
		renderBullet: function (index, className) {
			return '<span class="pagination__item pagination__item_current ' + className + '">0' + (index + 1) + '</span>';
		}
	},
});