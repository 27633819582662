/* jumbotron */
const jumbotronGallery = new Swiper('.jumbotron', {
	slidesPerView: 1,
	spaceBetween: 0,
	speed: 1000,
  	autoplay: {
		delay: 7000,
		disableOnInteraction: false,
	},
	navigation: {
		nextEl: '.jumbotron__btn_next',
		prevEl: '.jumbotron__btn_prev',
	},
});